import axios from "axios";
import { useState, useEffect } from "react";
import './MagnitForm.css';

const cities = [
    "Выберите город",
    "Абакан", "Абинск", "Александров", "Алексеевка", "Анжеро-Судженск", "Апатиты",
    "Архипо-Осиповка", "Асбест", "Ахтубинск", "Ачинск", "Балахна", "Балашов",
    "Безенчук", "Белебей", "Белово", "Белорецк", "Белореченск", "Благовещенск",
    "Бор", "Борисоглебск", "Боровичи", "Бугры", "Бугульма", "Бугуруслан", "Валуйки",
    "Витязево", "Вичуга", "Волжск", "Вольск", "Воркута", "Воткинск", "Всеволожск",
    "Выкса", "Вятские Поляны", "Георгиевск", "Горно-Алтайск", "Грязи", "Губкинский",
    "Гуково", "Дербент", "Дивноморское", "Динская", "Добрянка", "Донецк", "Донской",
    "Ейск", "Елец", "Елизаветинская", "Ефремов", "Железногорск (Красноярск)",
    "Железногорск (Курск)", "Жуковка", "Иглино", "Ирбит", "Ишим", "Ишимбай",
    "Кабардинка", "Каменск-Шахтинский", "Канаш", "Каневская", "Канск", "Каспийск",
    "Кимры", "Кингисепп", "Кинешма", "Кириши", "Кирово-Чепецк", "Кировск", "Киселевск",
    "Клин", "Клинцы", "Когалым", "Кореновск", "Котлас", "Кочетовка", "Красное Село",
    "Краснотурьинск", "Кропоткин", "Кузнецк", "Кумертау", "Кунгур", "Курганинск",
    "Лазаревское", "Лениногорск", "Ленинск-Кузнецкий", "Лесной", "Ливны", "Лиски",
    "Лысьва", "Междуреченск", "Мелеуз", "Михайловка", "Мичуринск", "Моздок",
    "Мончегорск", "Наро-Фоминск", "Новоалтайск", "Новодвинск", "Новозыбков",
    "Новотроицк", "Новоуральск", "Новошахтинск", "Ноябрьск", "Нягань", "Отрадный",
    "Павлово", "Первоуральск", "Печора", "Прокопьевск", "Протвино", "Прохладный",
    "Рубцовск", "Рузаевка", "Рыбинск", "Салехард", "Сарапул", "Саров", "Сасово",
    "Северск", "Сергиев Посад", "Серов", "Сибай", "Соликамск", "Старая Русса",
    "Строитель", "Темрюк", "Тимашевск", "Тихвин", "Тихорецк", "Торжок", "Троицк",
    "Туймазы", "Тутаев", "Урай", "Усинск", "Усть-Лабинск", "Ухта", "Хасавюрт",
    "Чапаевск", "Черкесск", "Чусовой", "Шерегеш", "Шуя", "Щекино", "Югорск", "Юрга"
];


export const FormMagnit = () => {
    const tg = window.Telegram.WebApp;
    let user_id = tg.initDataUnsafe.user.id;
    let fullname = tg.initDataUnsafe.user.first_name;
    let username = tg.initDataUnsafe.user.username
    tg.MainButton.setText('Запросить');
    tg.MainButton.show();
    const url = new URL(window.location.href)

    const chat_id = url.searchParams.get('tgWebAppStartParam')

    const date = new Date();

    const [data, setData] = useState({
        name: '',
        lastname: '',
        phone: '',
        city: cities[0],
        date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() + 1}`,
        start: '10:00',
        end: '18:00',
        chat_id: Number(chat_id)
    });

    useEffect(() => {
        axios.post(`https://tegaibro.ru:5001/api/magnitiko/${user_id}/data`, {
            username: username,
            fullname: fullname
        },
    {
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
            e => {
                if (!e.data.isData) return;
                setData(prevData => ({
                    ...prevData,
                    name: e.data.name,
                    lastname: e.data.lastname,
                    phone: e.data.phone
                }));
            }
        );
    }, [user_id]);

    useEffect(() => {
        const handleMainButtonClick = () => {
            if ([data.name, data.lastname, data.phone, data.city].some(field => field === '' || field.length < 3 || field === 'Выберите город')) {
                alert('Заполните все поля');
            } else {
                tg.MainButton.disable() // сделать неактивной 
                axios.post(`https://tegaibro.ru:5001/api/magnitiko/${user_id}/sendSlot`, data, {
                  headers: {
                    "Content-Type": "application/json"
                  }
                }).then(e => {
                  if (e.status === 200) {
                    tg.close();
                  }
                }).catch(e => {
                  if (e.status === 402 || e.status === 405) {
                    if (e.status === 402) alert('Запустите бота и попробуйте снова')
                    if (e.status === 405) alert('Минимальное время бронирования слота - 4 часа')
                  } else {
                    alert('Произошла ошибка')
                  }
                  tg.MainButton.enable()
                })
            }
        };
        
        tg.onEvent('mainButtonClicked', handleMainButtonClick);
        
        // Удаление обработчика при размонтировании компонента
        return () => tg.offEvent('mainButtonClicked', handleMainButtonClick);
    }, [data, tg, user_id, chat_id]);

    const onFocusNumber = (e) => {
        if (e.target.value.slice(0, 2) !== '+7')
            e.target.value = '+7'
    }

    return (
        <div className="form-container">
            <h2>Запрос слота</h2>
            <form>
                <div className="form-fi">
                    <label htmlFor="lastname">Фамилия</label>
                    <input type="text" id="lastname" placeholder="Введите фамилию" required value={data.lastname}
                        onChange={e => setData(prevData => ({ ...prevData, lastname: e.target.value }))} />
                    <label htmlFor="name">Имя</label>
                    <input type="text" id="name" placeholder="Введите имя" required value={data.name}
                        onChange={e => setData(prevData => ({ ...prevData, name: e.target.value }))} />
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Номер телефона</label>
                    <input type="tel" placeholder="Введите номер" id="phone" required value={data.phone} onFocus={onFocusNumber}
                        onChange={e => setData(prevData => ({ ...prevData, phone: e.target.value }))} />
                </div>
                <div className="form-group">
                    <label htmlFor="city">Город</label>
                    <select onChange={e => setData(prevData => ({...prevData, city: e.target.value}))} value={data.city}>
                        {cities.map(e => (<option key={e} value={e}>{e}</option>))}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="date">Дата</label>
                    <input type="date" id="date" value={data.date}
                        onChange={e => setData(prevData => ({ ...prevData, date: e.target.value }))}
                        required className="date-input" />
                </div>
                <div className="form-time">
                    <label htmlFor="start-time">С</label>
                    <input type="time" id="start-time" value={data.start}
                        onChange={e => setData(prevData => ({ ...prevData, start: e.target.value }))}
                        required className="time-input" />
                    <label htmlFor="end-time">До</label>
                    <input type="time" id="end-time" value={data.end}
                        onChange={e => setData(prevData => ({ ...prevData, end: e.target.value }))}
                        required className="time-input" />
                </div>
            </form>
        </div>
    );
};
